<template>
    <main>
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <h1>Please Sign In</h1>
                    <p>Please sign in to access page.</p>

                    <div class="login_panel">
                        <form action="/my/signin" method="post" @submit.prevent="signIn">
                            <div class="form-group">
                                <label>Email</label><input type="text" class="form-control" tabindex="1" v-model="formFields.email.val">
                            </div>
                            <div class="form-group">
                                <label>Password</label><a href="/pass_reset" class="pull-right small forgot-pass" tabindex="5">Forgot password?</a><input type="password" class="form-control" tabindex="2" v-model="formFields.password.val">
                            </div>
                            <input type="submit" value="Sign In" class="btn btn-primary" tabindex="3">
                            <router-link :to="'/my/register'" class="btn btn-default create-account" tabindex="4">
                    <span>
                        Create Your Account
                    </span>
                            </router-link>
                        </form>
                    </div>
                    <p><b>Website Update:</b> We have made a recent update to our website, if you experience any issues please report them at <a href="mailto:dhamm@cdicurbs.com">Dev</a>. We at CDI appreciate your business and feedback!</p>
                </div>
            </div>
        </div>
    </main>
</template>

<script>

import {store} from "@/store/BusinessObject.store";

export default {
    name: "SignInPage",
    data() {
        return {
            appState: this.$appStore.state,
            showLogIn: false,
            response: {},
            formFields: {
                email: { val: '', qsField: '[email]' },
                password: { val: '', qsField: '[passwd]' },
            },
            state: store.state,
            name: store.state.titlePlural
        }
    },
    methods: {
        signIn: function () {
            this.$appStore.signIn(this.formFields);
            this.formFields.email.val = '';
            this.formFields.password.val = '';

        },
        setView: function(view) {
            this.state.currentView = view;
        },

    }
}
</script>

<style scoped>

</style>




